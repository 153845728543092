<template>
	<div class="bg-light">
		<div class="bg1">
			<img src="../assets/banner.png" alt="" class="banner" />
			<div class="text-pos wid">
				<div class="container3 text wid">
					<div class="first">因为胜任 所以信任</div>
					<div class="senced">because do so trust</div>
				</div>
			</div>
		</div>
		<div class="containers">
			<div class="title">
				<img src="~@imgs/productTitle.png" alt="" />
			</div>
			<div class="titleList">
				<div class="titleItem" v-for="item in titleList" :key="item.id">
					<img :src="item.imgaddr" alt="" />
					<p style="font-size: 22px;color: #333232;">{{ item.title }}</p>
				</div>
			</div>
			<img src="~@imgs/ckgdbg.png" width="100px" height="20px" alt="" style="margin-top:45px"
				@click="goRouter('/productcenter')">
		</div>
		<div class="container1">
			<div class="title">
				<img src="~@imgs/gsjj.png" alt="" />
			</div>
			<div class="content">
				<p>云南康喜药业有限公司创建于2015年的云南康喜药业有限公司，是以药品、中药饮片、保健食品等为主的医药零售企业，公司注册资本300万元，截至2021年12月公司旗下拥有10家直营零售分店。
				<p>
				<p>多年来，公司始终坚持"质量第一，品质保证，顾客满意"的健康企业理念，秉承"负责，诚实，荣誉"的企业风格，以行业内先进零售药店模式为样板，积极探索，不断努力创新，创立了自己独有的发展策略及经营模式，康喜人在艰辛的创业道路上，历经变迁和磨砺，但康喜人的步履依然坚定，理想依然不灭，重审过去，面向未来，康喜人必将为实现公司效益化和规模化的发展，全力以赴，矢志不渝。
				</p>
				<p>未来发展规划主要为：</p>
				<p>
					1、成立云南康喜药业连锁公司，从“小、散、乱”到规模化，规范化、专业化转型升级
				</p>
				<p>
					2、在连锁公司管理下成立加盟店管理部，积极拓展加盟店业务,搭建及探索B2C销售模式，建立线上平台推广+线下门店配送的服务体系。
				</p>
			</div>
			<!-- <img src="~@imgs/ckgdbg.png" width="100px" height="20px" alt="" style="margin-top:45px"
				@click="goRouter('/companyprofile')"> -->
		</div>
		<!-- <div class="container2">
			<div class="title">
				<img src="~@imgs/xwzx.png" alt="" />
			</div>
			<div class="news">
				<div class="newsItem">
					<div class="newstitle">党支部联合开展主题党日活动</div>
					<div class="newscontent">
						3月29日下午，云红药业党支部联合广发行昆明海源支行党支部在公司3楼开展“银企共学共助”主题党日活动。活动首先参观了公司党建文化长廊，接着在党建大厅前重温入党誓词
					</div>
				</div>
				<div class="newsItem">
					<div class="newstitle">云红药业捐赠昆明市慈善总会4万余元</div>
					<div class="newscontent">
						为支持云南省昆明市地区医疗健康保险的发展，共同为构建和谐社会做出贡献，云南云红药业有限公司于3月24日对昆明市慈善总会进行无偿捐赠，捐赠资产为“春城惠民保保险”，首批捐赠保单600份，价值人民币41400元。
					</div>
				</div>
			</div>
			<img src="~@imgs/ckgdbg.png" width="100px" height="20px" alt="" @click="goRouter('/newscenter')">
		</div> -->
	</div>
</template>
<script>
	export default {
		data() {
			return {
				titleList: [{
						id: 0,
						imgaddr: require("@imgs/aj.jpg"),
						title: "阿胶"
					},
					{
						id: 1,
						imgaddr: require("@imgs/kcn.jpg"),
						title: "咳喘宁"
					},
					{
						id: 2,
						imgaddr: require("@imgs/zyjp.jpg"),
						title: "竹叶椒片"
					},
					{
						id: 3,
						imgaddr: require("@imgs/ajhm.jpg"),
						title: "氨咖黄敏胶囊"
					},
				],
			};
		},
		methods: {
			goRouter(value) {
				this.$router.push(value)
			}
		}
	};
</script>
<style lang="scss" scoped>
	.containers {
		margin-top: 75px;

		.title {
			margin: 20px 0 60px 0;
		}

		.titleList {
			width: 100%;
			display: flex;
			justify-content: center;
			align-items: center;

			.titleItem {
				width: 269px;
				height: 353px;
				background: #ffffff;
				border: 1px solid #cccccc;
				margin: 0 20px;
				padding-top: 30px;
				box-sizing: border-box;

				img {
					width: 100%;
					height: 220px;
				}
			}
		}
	}

	.container1 {
		width: 100%;
		height: 530px;
		background: #f3f3f3;
		display: flex;
		flex-direction: column;
		// justify-content: center;
		align-items: center;
		margin-top: 48px;

		.title {
			margin-top: 75px;
		}

		.content {
			width: 1198px;
			font-size: 18px;
			font-family: Microsoft YaHei;
			color: #666666;
			margin-top: 60px;
			text-align: left;
		}
	}

	.container3 {
		margin: auto;
		padding: 0 15px;
	}

	.container2 {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding-bottom: 45px;

		.title {
			margin-top: 73px;
		}

		.news {
			width: 100%;
			display: flex;
			justify-content: center;
			font-family: Microsoft YaHei;

			.newsItem {
				width: 561px;
				text-align: left;
				font-size: 18px;
				color: #666565;
				line-height: 30px;
				margin: 30px;

				.newstitle {
					font-size: 22px;
					font-weight: 400;
					color: #333232;
					line-height: 30px;
				}
			}
		}
	}

	.banner {
		width: 100%;
		height: 633px;
		margin-bottom: 7px;
	}

	.bg1 {
		position: relative;
	}

	.text-pos {
		position: absolute;
		top: 23%;
		left: 20%;
		color: brown !important;
		display: flex;
		justify-content: flex-start;
	}

	.text {
		text-align: left;
	}

	.first {
		font-size: 50px;
		font-family: Alegreya Sans SC ExtraBold;
		color: #fcfafc;
		animation: myfirst 1.5s linear;
	}

	.senced {
		font-size: 50px;
		font-family: Alegreya Sans SC ExtraBold;
		color: #fcfafc;
		animation: mysenced 1.5s linear;
	}

	.text-info {
		font-size: 32px;
		color: #17a2b8;
	}

	.center {
		text-align: center;
	}

	.m-bottom {
		margin-bottom: 21px;
	}

	.con-info {
		line-height: 2;
		text-align: left;
		font-size: 14px;
		text-indent: 28px;
	}

	@keyframes myfirst {
		0% {
			transform: translateY(-20px);
		}

		100% {
			transform: translateY(0px);
		}
	}

	@keyframes mysenced {
		0% {
			transform: translateY(-63px);
			opacity: 0;
		}

		80% {
			opacity: 0.5;
		}

		100% {
			transform: translateY(0px);
			opacity: 1;
		}
	}
</style>
